import React from 'react'
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import "./our-story.css"

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {title: {eq: "Our Story"}}}) {
      nodes {
          html
        }
    }
  }
`

const Story = ({data}) => {
    const markdown = data.allMarkdownRemark.nodes[0].html;
    console.log(markdown);
    return(
        <Layout
            pageClass="our-story"
            offsetHeader={true}
        >
            <Seo title="Our Story" />
            <section className="story-section">
                <div className="story-container container-max mt-50">
                    <div className="story-copy" dangerouslySetInnerHTML={{ __html: markdown }} />
                </div>
            </section>
        </Layout>
    )
}

export default Story
